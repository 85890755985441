<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">

		<div class="pa-5 pa-sm-10 fill-height" v-if="AppStore.started_app" id="questions-page">
			<v-container class="fill-height d-flex align-center flex-row">
				<v-row>
					<v-card elevation="0" v-show="!AppStore.toggleCGU" class="mx-auto py-5  px-2 px-sm-10" rounded="xl">
						<v-col cols="12" class="text-h2 pt-5 pb-7 text-center my-auto"
						       v-if="AppStore.data.configuration.display_question_title">
        						<span v-if="Helpers.isNegativeWithComment()"
						              v-html="AppStore.data.configuration[AppStore.locale].title_3_negative_with_comment"></span>
							<span v-if="Helpers.isNegativeWithoutComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_negative_without_comment"></span>
							<span v-if="Helpers.isPositiveWithComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_positive_with_comment"></span>
							<span v-if="Helpers.isPositiveWithoutComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_positive_without_comment"></span>
						</v-col>

						<v-col cols="12" id="question-questions"
						       v-if="questions_to_display && questions_to_display.length">
							<div v-for="(question, questionIndex) in questions_to_display"
							     :key="questionIndex+questionKey" class="question-question">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'slider'"
									:rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'text'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></text-view>
								<textarea-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'textarea'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'select'"
									:rules="question.is_required ? 'required' : ''"
									:name="questions_to_display[questionIndex].name"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'checkbox'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'radio'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'switch'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>

						<v-col cols="12" class="text-center">
							<div v-if="!AppStore.toggleCGU">
<!--								<v-btn-->
<!--									@click="passes(submit)"-->
<!--									:large="AppStore.data.general.button_size === 'large'"-->
<!--									:x-large="AppStore.data.general.button_size === 'x-large'"-->
<!--									:x-small="AppStore.data.general.button_size === 'x-small'"-->
<!--									:small="AppStore.data.general.button_size === 'small'"-->
<!--									v-if="answersLength === answersLengthes"-->
<!--									@keypress.enter="navigate" role="link" rounded-->
<!--									outlined elevation="0"-->
<!--									:color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"-->
<!--									class="revert-btn text-button"-->
<!--									:style="Helpers.getButtonSize(AppStore.data.general.button_size)"-->
<!--									v-html="AppStore.data.configuration[AppStore.locale].skip_btn">-->
<!--								</v-btn>-->
								<v-btn @click="passes(submit)" role="link" rounded
								       elevation="0" dark
								       :large="AppStore.data.general.button_size === 'large'"
								       :x-large="AppStore.data.general.button_size === 'x-large'"
								       :x-small="AppStore.data.general.button_size === 'x-small'"
								       :small="AppStore.data.general.button_size === 'small'"
								       @keypress.enter="navigate"
								       class="text-button"
								       :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"
								       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								       v-html="AppStore.data.configuration[AppStore.locale].send_btn">
								</v-btn>
							</div>
						</v-col>
					</v-card>
					<v-card v-show="AppStore.toggleCGU" elevation="0" class="pa-5 mx-5 mt-5 fill-height" rounded="xl"
					        id="cgu-card" style="overflow-y: auto;">
						<v-icon @click="AppStore.toggleCGU = false" class="float-right" size="30" id="close-cgu">mdi
							mdi-close
						</v-icon>
						<div v-html="AppStore.data.configuration[AppStore.locale].cgu_content"></div>
					</v-card>
					<v-col cols="12" class="text-center">
						<div v-show="AppStore.toggleCGU">
							<v-btn @click="AppStore.toggleCGU = false" role="link" rounded
							       elevation="0" dark
							       :large="AppStore.data.general.button_size === 'large'"
							       :x-large="AppStore.data.general.button_size === 'x-large'"
							       :x-small="AppStore.data.general.button_size === 'x-small'"
							       :small="AppStore.data.general.button_size === 'small'"
							       @keypress.enter="navigate"
							       class="text-button mt-3"
							       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       :color="AppStore.data.general.primary_color.hexa"
							       v-html="AppStore.data.configuration[AppStore.locale].cgu_close_btn">
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import Helpers from "../../../services/Helpers";
import SliderView from '../../../components/communs/inputViewer3/SliderView.vue'
import TextView from '../../../components/communs/inputViewer3/TextView.vue'
import TextareaView from '../../../components/communs/inputViewer3/TextareaView.vue'
import SelectView from '../../../components/communs/inputViewer3/SelectView.vue'
import CheckboxView from '../../../components/communs/inputViewer3/CheckboxView.vue'
import RadioView from '../../../components/communs/inputViewer3/RadioView.vue'
import SwitchView from '../../../components/communs/inputViewer3/SwitchView.vue'
import {EventBus} from "../../../services/Request";
import DividerView from "../../../components/communs/inputViewer3/DividerView.vue";
import {ValidationObserver} from 'vee-validate';

export default {
	name: 'questions-view',

	components: {
		DividerView,
		SliderView,
		TextView,
		TextareaView,
		SelectView,
		CheckboxView,
		RadioView,
		SwitchView,
		ValidationObserver
	},

	props: {},

	data: () => ({
		AppStore,
		Helpers,
		EventBus,
		answersLength: Object.keys(AppStore.rating.questions_answers).length,
		questions: [],
		questions_to_display: [],
		questionKey:0
	}),

	created() {
		setTimeout(() => {
			this.calculCguHeight()
		}, 300);

		window.addEventListener("orientationchange", () => {
			this.calculCguHeight()
		}, false);

		window.addEventListener("resize", () => {
			this.calculCguHeight()
		}, false);
	},

	async mounted() {
		EventBus.$on('refreshQuestions', (data) => {
			this.questionKey = this.questionKey+1
		});
		this.questions = []
		this.questions_to_display = []
		await this.getBookmarkedQuestions()
		if (AppStore.questions_by_page[4] && AppStore.questions_by_page[4].length > AppStore.data.configuration.questions_to_display_4) {
			await this.getRandomQuestions();
		} else {
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[4], (q) => {
				return !q.is_bookmarked
			}))
			this.questions_to_display = this.questions_to_display.concat(this.questions)
		}
	},

	computed: {
		answersLengthes() {
			return Object.keys(AppStore.rating.questions_answers).length
		}
	},

	watch: {
		_() {
			return _;
		},
	},

	methods: {
		getBookmarkedQuestions() {
			this.questions_to_display = Object.assign([], AppStore.bookmarkedQuestions[4])
		},

		getRandomQuestions() {
			const max = AppStore.data.configuration.questions_to_display_4 - this.questions_to_display.length
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[4], (q) => {
				return !q.is_bookmarked
			}))

			for (let i = 0; i < max; i++) {
				const randomQuestionIndex = Math.floor(Math.random() * (this.questions.length));
				this.questions_to_display.push(this.questions[randomQuestionIndex]);
				this.questions.splice(randomQuestionIndex, 1)
			}
		},
		calculCguHeight() {
			if(AppStore.data.configuration.display_header_title_1){
				const height = window.innerHeight - document.querySelector('#header').offsetHeight - document.querySelector('#footer').offsetHeight - 170 + 'px'
				document.getElementById("cgu-card").style.height = height;
			}
		},

		submit() {
			if (AppStore.data.configuration.skip_timeline_page) {
				this.$router.push({name: 'tb-3-home'})
			} else {
				this.$router.push({name: 'tb-3-timeline'})
			}

			EventBus.$emit('storeRating', 'noReset')
		},
	}
};
</script>
